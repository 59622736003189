'use client';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import { saveSearchQuery } from '@util/firestore/search';
import { setUserSearchHistory } from '@util/firestore/users';
import useDebounce from '@util/hooks/useDebounce';
import useSearchHistory from '@util/hooks/useSearchHistory';
import { logError } from '@util/logError';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { useToastContext } from 'context/ToastContext';
import { useQuerySuggestions } from 'features/search';
import type { BaseHit } from 'instantsearch.js';
import { Hit as SearchHit } from 'instantsearch.js';
import type { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import { useEffect } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import {
  Highlight,
  HitsProps,
  Index,
  useHits,
  useSearchBox,
} from 'react-instantsearch-hooks-web';
import SearchResultsSkeleton from './skeletons/SearchResultsSkeleton';

// HACK: some very odd wizardry to be able to create a CustomHits component with non-optional hitComponent
//       perhaps refactor this somewhere else along w/ its imports.
type CustomHitsProps<THit extends BaseHit> = HitsProps<THit> &
  Omit<HitsProps<THit>, 'hitComponent'> & {
    hitComponent: React.JSXElementConstructor<{
      hit: THit;
      sendEvent: SendEventForHits;
    }>;
  };
const CustomHits = (props: CustomHitsProps<any>) => {
  const { hits, sendEvent } = useHits(props);

  return (
    <>
      {hits.length !== 0 ? (
        <div className="grid px-[1rem] pb-[2rem] pt-[.5rem] lg:grid-cols-3 lg:px-0 lg:pb-0 lg:pt-0">
          {hits.map((hit, index) => (
            <props.hitComponent sendEvent={sendEvent} key={index} hit={hit} />
          ))}
        </div>
      ) : (
        <div className="m-[1rem] flex justify-center rounded-2xl bg-brand-lightest-gray p-[2rem]">
          <span>
            No matches found for your search query. Please try another keyword.
          </span>
        </div>
      )}
    </>
  );
};

const HitCardStyle =
  'relative flex cursor-pointer select-none items-center py-3 lg:pl-[2rem] pr-4 hover:bg-brand-lightest-gray rounded-md';

interface CustomHitProps<THit extends BaseHit> {
  hit: SearchHit<THit>;
  sendEvent: (type: string, hit: SearchHit<THit>, eventName: string) => void;
}

function ProductHit({ hit, sendEvent }: CustomHitProps<ProductDocument>) {
  const { userDoc } = useAuth();
  const { debouncedSearchTerm } = useSearch();
  return (
    <>
      <div
        key={hit.id}
        className={HitCardStyle}
        onClick={() => {
          sendEvent('click', hit, 'Hit Clicked');
        }}
      >
        <Link
          href={getProductSlug(hit)}
          className="flex w-full items-center"
          onClick={() => {
            if (!!userDoc) {
              trackUserInteraction({
                uid: userDoc.uid,
                pid: hit.id,
                interaction: 'search_clicked',
              });
              saveSearchQuery({ query: hit.title, uid: userDoc.uid });
            }
            if (debouncedSearchTerm) {
              trackUserSearch({
                term: debouncedSearchTerm,
                product_clicked: hit.id,
              });
            }
          }}
        >
          <SafeImage
            alt={hit.title}
            src={hit.thumbnail}
            style={{ objectFit: 'cover' }}
            height={42}
            width={42}
            className="mr-5 h-[4.2rem] w-[4.2rem] rounded-md"
          />
          <div>
            <Highlight attribute="title" hit={hit} />
            <div className="flex text-lg font-light text-brand-blue">
              {hit.categories?.map((c, i, arr) => {
                return (
                  <>
                    {c}
                    {i < arr.length - 1 && (
                      <div className="text-1xl px-3 leading-6">/</div>
                    )}
                  </>
                );
              })}
            </div>
            {/* {hit.on_sale ? (
              <div className="flex items-center gap-2">
                <div className="  text-gray-500 line-through">
                  {formatCurrency(hit.og_price)}
                </div>
                <div className=" font-semibold text-brand-secondary ">
                  {formatCurrency(hit.price)}
                </div>
                <div className="rounded-full bg-brand-secondary p-2 text-white">
                  On Sale!
                </div>
              </div>
            ) : (
              <div className="font-semibold">{formatCurrency(hit.price)}</div>
            )} */}
          </div>
        </Link>
      </div>
    </>
  );
}

function CategoryHit({ hit }: CustomHitProps<any>) {
  return (
    <>
      <div key={hit.id} className={HitCardStyle}>
        <Link href={hit.full_slug} className="flex w-full items-center">
          {/* <SafeImage
            alt={hit.name}
            src={hit.image}
            style={{ objectFit: 'contain' }}
            height={42}
            width={42}
            className="mr-5 h-[4.2rem] w-[4.2rem] rounded-md"
          /> */}
          <div className="flex items-center gap-2">
            <Highlight attribute={'product_cats.ride_type'} hit={hit} />
            <Highlight attribute="title" hit={hit} />
          </div>
        </Link>
      </div>
    </>
  );
}

function SellerHit({ hit, sendEvent }: CustomHitProps<any>) {
  return (
    <>
      <div
        key={hit.uid}
        className={HitCardStyle}
        onClick={() => {
          sendEvent('click', hit, 'seller hit clicked');
        }}
      >
        <Link
          href={'/profile/' + hit.username_slug}
          className="flex w-full items-center justify-between"
        >
          <div className="flex items-center gap-[1.6rem]">
            <Avatar user={hit} size="small" />

            <div className="flex flex-col">
              <Highlight
                attribute="username"
                hit={hit}
                className="block truncate text-[1.7rem] font-medium text-zinc-600"
              />
            </div>
          </div>

          <ArrowUpRightIcon className="h-[2rem] w-[2rem] text-zinc-500" />
        </Link>
      </div>
    </>
  );
}

// TODO: this should start fetching before the user even opens the searchbox. it should also be memoized to
//       reduce excess calls to typesense. there is a large mixture of UI and business state in here, so would
//       be nice to also refactor some of that out.
export function LastSearchSuggestions() {
  const { userDoc } = useAuth();
  const { showErrorToast, showSuccessToast } = useToastContext();
  const queryClient = useQueryClient();

  const searchHistory = useSearchHistory({
    uid: userDoc?.uid,
  });

  const onClearClick = async () => {
    try {
      await setUserSearchHistory(userDoc?.uid ?? '', []);

      showSuccessToast('Search history cleared');

      queryClient.invalidateQueries({
        queryKey: ['searchHistory', userDoc?.uid],
      });
    } catch (error) {
      logError(error);
      showErrorToast('Something went wrong');
    }
  };

  return (
    <div className="hidden min-w-[38rem] max-w-[48rem] pr-[3rem] sm:flex lg:border-r">
      <div className="mt-[1rem] flex flex-col lg:ml-[9rem]">
        <p className="text-brand mb-1 block font-bold">Search history</p>
        <div className="flex w-screen gap-[0.8rem] overflow-x-scroll pr-[5rem] lg:w-full lg:flex-col lg:overflow-x-auto">
          {searchHistory.length > 0 ? (
            searchHistory.slice(0, 6).map((search, index) => (
              <Link
                href={`/search?keyword=${search}`}
                className="my-[0.7rem] flex flex-row items-center whitespace-nowrap rounded-2xl  border border-zinc-200 px-[1.2rem] py-[0.7rem] hover:cursor-pointer hover:bg-brand-lightest-gray lg:whitespace-normal"
                key={index}
              >
                <span className="mr-[1rem] flex grow">{`${search}`}</span>
                <div>
                  <FiArrowUpRight />
                </div>
              </Link>
            ))
          ) : !!userDoc ? (
            <p>No search history yet!</p>
          ) : (
            <div className="mt-4">
              <Button
                href="/signup"
                height="small"
                width="small"
                type="secondary"
                text="Sign up to see search history"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const Suggestions = () => {
  const { searchTerm } = useSearch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { suggestions } = useQuerySuggestions(debouncedSearchTerm ?? '');

  return (
    <div className="mt-4 flex w-full items-center lg:min-w-[50rem] lg:px-4">
      <div className="flex w-full flex-col">
        <SectionTitle title="Suggestions" />

        <div className="mt-4 flex flex-col sm:grid sm:min-w-[50rem] sm:grid-cols-5 sm:gap-4">
          {suggestions.map((search) => (
            <Link
              key={search.document.q}
              href={`/search?keyword=${encodeURIComponent(search.document.q)}`}
              className="flex flex-row items-center justify-between whitespace-nowrap rounded-2xl px-5 py-4 hover:cursor-pointer hover:bg-brand-lightest-gray sm:gap-x-4 sm:border lg:whitespace-normal"
            >
              {search.highlight.q?.snippet ? (
                <div
                  className="highlighted-search flex w-[40rem] items-center gap-x-2 truncate text-ellipsis sm:w-[15rem]"
                  dangerouslySetInnerHTML={{
                    __html: search.highlight.q.snippet,
                  }}
                ></div>
              ) : (
                <div className="flex w-[40rem] items-center gap-x-2 truncate">
                  {search.document.q}
                </div>
              )}

              <FiArrowUpRight className="h-6 w-6 text-zinc-500" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

// TODO: need to make sure this appears/disappears when it should
//       i think it currently doesnt go away when visiting a product page
const SearchResultsPreview = () => {
  const { searchTerm, debouncedSearchTerm } = useSearch();
  const { refine } = useSearchBox();
  const { userDoc } = useAuth();

  useEffect(() => {
    if (debouncedSearchTerm) refine(debouncedSearchTerm);
  }, [debouncedSearchTerm, refine]);

  // if (searchTerm === '')
  //   return (
  //     <div className="flex flex-col overflow-x-hidden overflow-y-hidden p-4 lg:min-h-screen lg:flex-row lg:overflow-x-auto lg:overflow-y-auto">

  //     </div>
  //   );

  return (
    <>
      {debouncedSearchTerm ? (
        <div className="flex flex-col overflow-x-hidden overflow-y-hidden p-4 lg:min-h-screen lg:flex-row lg:overflow-x-auto lg:overflow-y-auto">
          <div className="hidden max-w-[60rem] sm:flex">
            <LastSearchSuggestions />
          </div>

          <div className="flex grow flex-col gap-y-[2rem] overflow-y-scroll lg:ml-[2rem] lg:mr-[6rem]">
            <Suggestions />

            <div className="my-[1rem] flex flex-row gap-[0.6rem] lg:m-[1rem]">
              <SectionTitle
                title="Products"
                helperElement={
                  <Link
                    href={`/search?keyword=${searchTerm}`}
                    className="text-brand-secondary"
                    onClick={() => {
                      if (!!userDoc) {
                        saveSearchQuery({
                          query: searchTerm ?? '',
                          uid: userDoc.uid,
                        });
                      }
                    }}
                  >
                    View All
                  </Link>
                }
              />
            </div>

            <Index indexName={'products_alias'}>
              <CustomHits hitComponent={ProductHit} />
            </Index>

            <SectionTitle title="Categories" />

            <Index indexName="categories_alias">
              <CustomHits hitComponent={CategoryHit} />
            </Index>

            <SectionTitle title="Sellers" />

            <Index indexName={'sellers'}>
              <CustomHits hitComponent={SellerHit} />
            </Index>
          </div>
        </div>
      ) : searchTerm !== undefined ? (
        <SearchResultsSkeleton />
      ) : null}
    </>
  );
};

const SectionTitle = ({
  title,
  helperElement,
}: {
  title: string;
  helperElement?: React.ReactNode;
}) => {
  if (helperElement) {
    return (
      <div className="flex w-full flex-row items-center justify-between px-[1rem]">
        <p className="text-brand mb-1 block text-[1.8rem] font-semibold">
          {title}
        </p>
        {helperElement}
      </div>
    );
  }

  return (
    <div className="w-full px-[1rem]">
      <p className="text-brand mb-1 block text-[1.8rem] font-semibold">
        {title}
      </p>
    </div>
  );
};

export default SearchResultsPreview;
